import AppTextField from "components/Base/AppTextfield";
import AppButton from "components/Base/AppButton";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { resetConfirm } from "services/auth";
import { useLocation } from 'react-router-dom';
import { useState } from "react";

const ConfirmResetForm = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const validationSchema = Yup.object({
    password1: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    password2: Yup.string()
      .oneOf([Yup.ref("password1")], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const [serverError, setServerError] = useState('');

  const formik = useFormik({
    initialValues: {
      password1: "",
      password2: "",
    },
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema,
    onSubmit: () => {
      const params = new URLSearchParams(search);
      const email = params.get('reset_email') || "";
      const token = params.get('token') || "";

      

      if (email && token) {
        serverError && setServerError('');

        const res = resetConfirm(email, formik.values.password1, token).then((res) => {
          if (res.status === 200) {
            navigate("/dashboard");
          } else if (res.isError) {
            setServerError( res.message || 'An error occurred. Please try again.');
          }
          else {
            console.error(res);
          }
        });
      }
      else{
        console.error('No email or token found!');
        navigate("/login");
      }
    },
  });

  return (
    <div className="w-full flex flex-col items-center gap-6">
      <p className="text-[22px] font-semibold leading-[33px] text-center">
        Register
      </p>

      <form
        onSubmit={formik.handleSubmit}
        className="max-w-[280px] mx-auto flex flex-col gap-[20px] mt-2"
      >
        <div>
          <AppTextField
            placeholder="Enter New Password"
            className="h-[54px] rounded-[60px]"
            name="password1"
            type="password"
            value={formik.values.password1}
            onChange={(e) => {
              formik.handleChange(e);
              formik.setFieldTouched("password", true, true);
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.password1 && formik.errors.password1 ? (
            <div className="text-red-500 text-center mt-1 text-xs">
              {formik.errors.password1}
            </div>
          ) : null}
        </div>

        <div>
          <AppTextField
            placeholder="Confirm Password"
            className="h-[54px] rounded-[60px]"
            name="password2"
            type="password"
            value={formik.values.password2}
            onChange={(e) => {
              formik.handleChange(e);
              formik.setFieldTouched("password", true, true);
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.password2 && formik.errors.password2 ? (
            <div className="text-red-500 text-center mt-1 text-xs">
              {formik.errors.password2}
            </div>
          ) : null}
        </div>

        {serverError && (
          <div className="text-red-500 text-center mt-1 text-xs">
            {serverError}
          </div>
        )}
        
        <AppButton
          className="h-[54px] rounded-[60px] w-full max-w-[280px] mx-auto mt-10"
          type="submit"
        >
          Save and Continue
        </AppButton>
      </form>

    </div>
  );
};

export default ConfirmResetForm;
