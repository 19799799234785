import AppTextField from "components/Base/AppTextfield";
import AppButton from "components/Base/AppButton";
import GoogleImg from "assets/images/google.svg";
import FacebookImg from "assets/images/facebook.svg";
import EmailImg from "assets/images/email-round-line.svg";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { login, googleLogin, facebookLogin, checkLogin } from "services/auth";
import { useEffect, useState } from "react";

const LoginForm = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const isPwdReset = params.get("reset") === "true";
  const [isLoginError, setIsLoginError] = useState(false);

  useEffect(() => {
    checkLogin().then((isSignedIn) => {
      console.log(isSignedIn ? "already signed in" : "not signed in");
      if (isSignedIn) {
        const timeout = setTimeout(() => {
          navigate("/dashboard");
        }, 500);
        return () => clearTimeout(timeout);
      }
    });
  }, []);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema,
    onSubmit: () => {
      isLoginError && setIsLoginError(false);

      const res = login(formik.values.email, formik.values.password).then((res) => {
        if (res.status === 200) {
          navigate("/dashboard");
        } else if (res.isError) {
          setIsLoginError(true);
        }
        else {
          console.error(res);
        }
      });
    },
  });

  return (
    <div className="w-full flex flex-col items-center gap-6">
      <p className="text-[22px] font-semibold leading-[33px] text-center">
        Sign In
      </p>

      <form
        onSubmit={formik.handleSubmit}
        className="max-w-[280px] mx-auto flex flex-col gap-[20px] mt-2"
      >
        <div>
          <AppTextField
            placeholder="Enter Email"
            className="h-[54px] rounded-[60px]"
            name="email"
            value={formik.values.email}
            onChange={(e) => {
              formik.handleChange(e);
              formik.setFieldTouched("email", true, true);
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="text-red-500 text-center mt-1 text-xs">
              {formik.errors.email}
            </div>
          ) : null}
        </div>

        <div>
          <AppTextField
            placeholder="Enter Password"
            className="h-[54px] rounded-[60px]"
            name="password"
            type="password"
            value={formik.values.password}
            onChange={(e) => {
              formik.handleChange(e);
              formik.setFieldTouched("password", true, true);
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="text-red-500 text-center mt-1 text-xs">
              {formik.errors.password}
            </div>
          ) : null}
        </div>

        {!isPwdReset ? (
          <p className="text-sm font-medium leading-[21px] text-center ">
            {isLoginError ? (<><span className="text-red-500 text-xs">Invalid email or password try again</span> <br /><br /></>) : null}
            <span
              className="underline underline-offset-1 text-tertiary cursor-pointer"
              onClick={() => {
                navigate("/forgot-password");
              }}
            >
              Forgot your password?
            </span>
          </p>
        ) : (
          <p className="text-xs font-light leading-[21px] text-center  text-red-500">
            Check your email for password reset instructions
          </p>
        )}

        <AppButton
          className="h-[54px] rounded-[60px] w-full max-w-[280px] mx-auto mt-10"
          type="submit"
        >
          Login
        </AppButton>
      </form>

      <div className="flex flex-row items-center gap-2 mt-5 mb-3">
        <hr className="text-tertiary-100 bg-tertiary-100 h-[1px] w-[40px]" />
        <p className="text-center text-tertiary-100">sign up</p>
        <hr className="text-tertiary-100 bg-tertiary-100 h-[1px] w-[40px]" />
      </div>

      <div className="flex flex-row gap-4 items-center">
        <img
            src={EmailImg}
            alt="Email"
            className="w-[44px] h-[44px] cursor-pointer"
            onClick={() => {
              navigate("/register");
            }}
        />
        <img
          src={GoogleImg}
          alt="Google"
          className="w-[44px] h-[44px] cursor-pointer"
          onClick={() => {
            window.location.href = "/api/externalauth/google";
            // googleLogin().then(() => {
            //   console.log("Google login success");
            // });
          }}
        />
        <img
          src={FacebookImg}
          alt="Facebook"
          className="w-[44px] h-[44px] cursor-pointer"
          onClick={() => {
            window.location.href = "/api/externalauth/facebook";
            // facebookLogin().then(() => {
            //   console.log("Facebook login success");
            // });
          }}
        />
      </div>
    </div>
  );
};

export default LoginForm;
