import AppTextField from "components/Base/AppTextfield";
import AppButton from "components/Base/AppButton";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { register } from "services/auth";
import ReCAPTCHA from 'react-google-recaptcha';
import { useState } from "react";

const RegisterForm = () => {
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    recaptcha: Yup.string().required("Please verify you're not a robot"),
  });

  const [serverError, setServerError] = useState('');

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      recaptcha: '',
    },
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema,
    onSubmit: () => {
      serverError && setServerError('');

      const res = register(formik.values.email, formik.values.password, formik.values.recaptcha).then((res) => {
        if (res.status === 200) {
          navigate("/check-email");
        } else if (res.isError) {
          setServerError( res.message || 'An error occurred. Please try again.');
        }
        else {
          // Show error message
          console.error(res);
        }
      });
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const honeypot = formData.get('name');
    if (honeypot) return; // Bot detected
    formik.handleSubmit(e);
  };

  return (
    <div className="w-full flex flex-col items-center gap-6">
      <p className="text-[22px] font-semibold leading-[33px] text-center">
        Register
      </p>

      <form
        onSubmit={handleSubmit}
        className="max-w-[280px] mx-auto flex flex-col gap-[20px] mt-2"
      >
        <input type="text" name="name" className="hidden" />

        <div>
          <AppTextField
            placeholder="Enter Email"
            className="h-[54px] rounded-[60px]"
            name="email"
            value={formik.values.email}
            onChange={(e) => {
              formik.handleChange(e);
              formik.setFieldTouched("email", true, true);
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="text-red-500 text-center mt-1 text-xs">
              {formik.errors.email}
            </div>
          ) : null}
        </div>

        <div>
          <AppTextField
            placeholder="Enter Password"
            className="h-[54px] rounded-[60px]"
            name="password"
            type="password"
            value={formik.values.password}
            onChange={(e) => {
              formik.handleChange(e);
              formik.setFieldTouched("password", true, true);
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="text-red-500 text-center mt-1 text-xs">
              {formik.errors.password}
            </div>
          ) : null}
        </div>

        <ReCAPTCHA
          sitekey="6LeBNn4qAAAAACuq4mKSon-KLTdKJdvSQiCGAmtz"
          onChange={(token: string | null) => formik.setFieldValue('recaptcha', token)}
        />
        {formik.touched.recaptcha && formik.errors.recaptcha && (
          <div className="text-red-500 text-center mt-1 text-xs">
            {formik.errors.recaptcha}
          </div>
        )}

        {serverError && (
          <div className="text-red-500 text-center mt-1 text-xs">
            {serverError}
          </div>
        )}

        
        <AppButton
          className="h-[54px] rounded-[60px] w-full max-w-[280px] mx-auto mt-10"
          type="submit"
        >
          Register
        </AppButton>
      </form>

    </div>
  );
};

export default RegisterForm;
