import React from "react";
import CircleTickImg from "assets/images/tick-circle.svg";

interface SubscriptionPlanProps {
  name: string;
  feature: string[];
  isPopular: boolean;
  price: string;
  isSelected: boolean;
  icon: any;
  currency: string;
  onSubscriptionPlanClick: (name: string) => void;
}

const SubscriptionPlan: React.FC<SubscriptionPlanProps> = ({
  name,
  feature,
  isPopular,
  price,
  isSelected,
  icon,
  currency,
  onSubscriptionPlanClick,
}) => {
  return (
    <div
      className={`cursor-pointer min-h-[170px] max-w-[350px] lg:max-w-[400px] app-card rounded-[26px] px-4 py-5 flex flex-col gap-2 justify-between w-full
        ${
          isSelected
            ? "border-2 border-solid border-primary-100"
            : "border-white"
        }
        `}
      style={{ borderImageSource: "none" }}
      onClick={() => onSubscriptionPlanClick(name)}
    >
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row  items-center gap-2">
          <div className="w-[54px] h-[54px] flex flex-col items-center rounded-[16px] justify-center bg-white">
            <img src={icon} alt="icon" className="w-[28px] h-[28px]" />
          </div>

          <div>
            <p className="text-lg font-medium leading-[27px]">{name}</p>
            {!!price && (
              <p className="text-[10px] font-normal">
                <span className="font-semibold text-sm">
                  {currency}
                  {price}
                </span>
                /Month
              </p>
            )}
          </div>
        </div>
        <div className="flex flex-row items-center gap-2 flex-wrap">
          {isPopular && (
            <p className="bg-[#10ACE71A] rounded-[60px] py-[5px] px-[10px] text-[10px] text-[#22ACE0] ">
              Popular
            </p>
          )}
          <span
            className={`flex items-center justify-center w-[30px] h-[30px] rounded-full border border-solid
            ${
              isSelected
                ? "border-primary-100"
                : "border-[#C5CFDA] bg-[#F5F5F5]"
            }`}
          >
            {/* Inner Circle (filled when checked) */}
            <span
              className={`w-[18px] h-[18px] rounded-full transition-colors 
              ${isSelected ? "bg-primary-100" : "border-none "}`}
            />
          </span>
        </div>
      </div>

      <div className="flex h-full flex-col gap-4 text-sm">
        {feature.map((desc, i) => {
          return (
            <div key={i} className=" flex flex-row gap-1 items-center">
              <img
                src={CircleTickImg}
                alt="tick"
                className="w-[18px] h-[18px]"
              />
              <p className="text-primary-200">{desc}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SubscriptionPlan;
