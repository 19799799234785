import React from "react";

interface AppRadioButtonProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  checked: boolean;
}

const AppRadioButton: React.FC<AppRadioButtonProps> = ({
  label,
  checked,
  ...props
}) => {
  return (
    <label className="cursor-pointer select-none w-[134px] h-[54px]">
      <input
        type="radio"
        checked={checked}
        className="hidden peer"
        {...props}
      />
      <span
        className={`flex items-center space-x-2 px-3 py-2 rounded-full border border-solid h-full transition-colors 
          ${
            checked
              ? "border-primary-100 bg-primary-light"
              : "border-gray-300 bg-primary-light"
          }`}
      >
        {/* Outer Circle (with border) */}
        <span
          className={`flex items-center justify-center w-7 h-7 rounded-full border border-solid
            ${
              checked ? "border-primary-100" : "border-[#C5CFDA] bg-[#F5F5F5]"
            }`}
        >
          {/* Inner Circle (filled when checked) */}
          <span
            className={`w-5 h-5 rounded-full transition-colors 
              ${checked ? "bg-primary-100" : "border-none "}`}
          />
        </span>
        {/* Label Text */}
        <span
          className={`${
            checked ? "text-primary-700" : "text-gray-700"
          } font-medium`}
        >
          {label}
        </span>
      </span>
    </label>
  );
};

export default AppRadioButton;
