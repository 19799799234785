import AppTextField from "components/Base/AppTextfield";
import AppButton from "components/Base/AppButton";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { requestReset } from "services/auth";
import { useState } from "react";

const ForgotPasswordForm = () => {
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
  });

  const [serverError, setServerError] = useState('');

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema,
    onSubmit: () => {
      serverError && setServerError('');

      const res = requestReset(formik.values.email).then((res) => {
        if (res.status === 200) {
          navigate("/login?reset=true");
        } else if (res.isError) {
          setServerError( res.message || 'An error occurred. Please try again.');
        }
        else {
          console.error(res);
        }
      });

    },
  });

  return (
    <div className="w-full flex flex-col items-center gap-6">
      <p className="text-[22px] font-semibold leading-[33px] text-center pb-5">
        Request a Password Reset
      </p>

      <form
        onSubmit={formik.handleSubmit}
        className="max-w-[280px] mx-auto flex flex-col gap-[20px] mt-2"
      >
        <div>
          <AppTextField
            placeholder="Enter Email"
            className="h-[54px] rounded-[60px]"
            name="email"
            value={formik.values.email}
            onChange={(e) => {
              formik.handleChange(e);
              formik.setFieldTouched("email", true, true);
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="text-red-500 text-center mt-1 text-xs">
              {formik.errors.email}
            </div>
          ) : null}
        </div>

        {serverError && (
          <div className="text-red-500 text-center mt-1 text-xs">
            {serverError}
          </div>
        )}

        
        <AppButton
          className="h-[54px] rounded-[60px] w-full max-w-[280px] mx-auto mt-10 px-5"
          type="submit"
          hideIcon
        >
          Reset Password
        </AppButton>
      </form>

    </div>
  );
};

export default ForgotPasswordForm;
