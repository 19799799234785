import { useState, useEffect } from "react";
import AppLayout from "components/Layout/AppLayout";
import AppButton from "components/Base/AppButton";
import SubscriptionPlan from "components/Subscription/SubscriptionPlan";
import FreePlan from "assets/images/free-plan.svg";
import CasualPlan from "assets/images/casual-plan.svg";
import ZenPlan from "assets/images/zen-plan.svg";
import { getPlans, Plan, subscribe } from "services/plans";
import { useNavigate } from "react-router-dom";


const icons: Record<string, string> = {
  FreePlan,
  CasualPlan,
  ZenPlan,
};

export interface SubscriptionPlanData {
  id: number;
  name: string;
  features: string[];
  price: string;
  currency: string;
  billingInterval: string;
  answerLimit: number;
  answerResetPeriodSeconds: number;
  isPopular: boolean;
  isSelected: boolean;
  icon: any;
}

const SubscriptionPage = () => {
  const navigate = useNavigate();
  
  const [plans, setPlans] = useState<SubscriptionPlanData[]>([]);
  const [currentPlanId, setCurrentPlanId] = useState<number>(0);

  const fetchPlans = async () => {
    const response = await getPlans();
    const plans = response?.map((plan: Plan) => ({
      id: plan.id,
      name: plan.name,
      features: plan.description?.split("\n"),
      price: (plan.priceCents / 100.0).toFixed(2),
      currency: plan.currency,
      billingInterval: plan.billingInterval,
      answerLimit: plan.answerLimit,
      answerResetPeriodSeconds: plan.answerResetPeriodSeconds,
      isPopular: plan.isPopular,
      isSelected: plan.isSubscribed,
      icon: icons[`${plan.name}Plan`],
    }));

    setPlans(plans);
    setCurrentPlanId(plans.find((p) => p.isSelected)?.id || 0);
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  const handleSubscribe = async (planId: number) => {
    try {
      const response = await subscribe(planId);
      if(response.url)
        window.location.href = response.url;
      else
        navigate("/dashboard");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AppLayout navigation title="Choose a Plan">
      <div className="flex flex-col gap-3">
        {plans.map((plan) => (
          <SubscriptionPlan
            key={plan.id}
            name={plan.name}
            feature={plan.features}
            isPopular={plan.isPopular}
            price={plan.price}
            isSelected={plan.isSelected}
            icon={plan.icon}
            currency={plan.currency}
            onSubscriptionPlanClick={(name: string) => {
              const newPlans = plans.map((p) => ({
                ...p,
                isSelected: p.name === name,
              }));
              setPlans(newPlans);
            }}
          />
        ))}
      </div>

      <div className="w-full items-center flex">
        <AppButton
          onClick={() => {
            handleSubscribe(plans.find((p) => p.isSelected)?.id || 0);
          }}
          variant="outline"
          className="max-w-[280px] w-full mx-auto mt-10 h-[54px]"
          hideIcon
          disabled={plans.find((p) => p.isSelected)?.id === currentPlanId}
        >
          Subscribe
        </AppButton>
      </div>
    </AppLayout>
  );
};

export default SubscriptionPage;
