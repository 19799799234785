// Example using React
import { useEffect } from 'react';
import {  useLocation, useNavigate } from 'react-router-dom';

const ExternalAuthLanding = () => {
    const navigate = useNavigate();
    const { search } = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(search);
        const token = params.get('token');

        if (token) {
            localStorage.setItem('token', token);
            navigate('/about-you');
        } else {
            navigate('/login');
        }
    }, []);

    return <div>Loading...</div>;
};

export default ExternalAuthLanding;
