import MessageImg from "assets/images/message.svg";
import HomeImg from "assets/images/home.svg";
import ProfileImg from "assets/images/profile.svg";
import SubscribeImg from "assets/images/dollar-coin-solid-icon.svg";
import WaveMenuImg from "assets/images/wave-menu.svg";
import { useNavigate } from "react-router-dom";

const Menu = [
  {
    name: "Home",
    icon: HomeImg,
    route: "/dashboard",
    enabled: true,
  },
  {
    name: "Answers",
    icon: MessageImg,
    route: "/answers",
    enabled: true,
  },
  {
    name: "Select Plan",
    icon: SubscribeImg,
    route: "/subscription",
    enabled: true,
  },
  {
    name: "Profile",
    icon: ProfileImg,
    route: "/about-you",
    enabled: true,
  },
];

const AppMenu = () => {

  const navigate = useNavigate();

  const handleClick = (route: string) => {
    navigate(route);
  };

  return (
    <div className="w-full max-w-[350px] h-[70px] rounded-[60px] bg-white px-5 flex flex-row gap-1 mb-5">
      {Menu.map((item, index) => {
        return (
          <div
            key={index}
            className="flex items-center justify-center w-full relative cursor-pointer"
            onClick={() => handleClick(item.route)}
          >
            <img
              src={item.icon}
              alt={item.name}
              className="w-[24px] h-[24px]"
            />
            {item.route === "/dashboard" && (
              <img
                src={WaveMenuImg}
                alt="Wave"
                className="w-[63px] h-[13px] absolute bottom-0"
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default AppMenu;
