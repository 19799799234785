import { AxiosError } from "axios";
import apiInstance, { ApiResponse } from "services";

export const login = async (email: string, password: string): Promise<ApiResponse<any>>  => {
  try {
    const response = await apiInstance.post("/api/auth/login", {
      email,
      password,
    });
    const token = response.data.token;
    localStorage.setItem("token", token);

    return {
      data: undefined,
      status: response.status,
      isError: false,
      message: ''
    }
  } catch (error: AxiosError | any) {
    const resp = {
      data: undefined,
      status: -1,
      isError: true,
      message: ''
    }
    if(error.response){
      resp.status = error.response.status
      resp.message = error.response.data
    }
    console.error(error);

    return resp;
  }

};

export const checkLogin = async (): Promise<boolean>  => {
  let isLoggedIn = false;
  
  try {
    const response = await apiInstance.get("/api/auth/check-login");
    isLoggedIn = response.status === 200;
  } catch (error) {
    isLoggedIn = false;
  }

  if (!isLoggedIn) {
    localStorage.removeItem("token");
  }

  return isLoggedIn;
};

export const register = async (email: string, password: string, recaptcha: string): Promise<ApiResponse<boolean>> => {
  try {
    const response = await apiInstance.post("/api/auth/register", {
      Email: email,
      Password: password,
      RecaptchaToken: recaptcha
    });

    return {
      data: true,
      status: response.status,
      isError: false,
      message: ''
    }

  } catch (error: AxiosError | any) {
    const resp = {
      data: false,
      status: -1,
      isError: true,
      message: ''
    }

    if(error.response){
      resp.status = error.response.status
      resp.message = error.response.data
    }

    console.error(error);

    return resp;
  }
};

export const confirm = async (email: string, token: string): Promise<ApiResponse<any>> => {
  try {
    const response = await apiInstance.post("/api/auth/confirm", {
      email,
      token,
    });
    const authToken = response.data.token;
    localStorage.setItem("token", authToken);

    return {
      data: undefined,
      status: response.status,
      isError: false,
      message: ''
    }
  } catch (error: AxiosError | any) {
    const resp = {
      data: undefined,
      status: -1,
      isError: true,
      message: ''
    }
    if(error.response){
      resp.status = error.response.status
      resp.message = error.response.data
    }

    console.error(error);
    return resp;
  }
};

export const requestReset = async (email: string): Promise<ApiResponse<any>> => {
  try {
    const response = await apiInstance.post("/api/auth/password-reset", {
      email,
    });

    return {
      data: undefined,
      status: response.status,
      isError: false,
      message: ''
    }

  } catch (error: AxiosError | any) {
    const resp = {
      data: undefined,
      status: -1,
      isError: true,
      message: ''
    }
    if(error.response){
      resp.status = error.response.status
      resp.message = error.response.data
    }
    console.error(error);

    return resp;
  }
};

export const resetConfirm = async (email: string, password: string, token: string): Promise<ApiResponse<any>> => {
  try {
    const response = await apiInstance.post("/api/auth/password-reset/confirm", {
      email,
      password,
      token,
    });

    const authToken = response.data.token;
    localStorage.setItem("token", authToken);

    return {
      data: undefined,
      status: response.status,
      isError: false,
      message: ''
    }
  } catch (error: AxiosError | any) {
    const resp = {
      data: undefined,
      status: -1,
      isError: true,
      message: ''
    }
    if(error.response){
      resp.status = error.response.status
      resp.message = error.response.data
    }

    console.error(error);

    return resp;
  }
};

export const googleLogin = async () => {
  try {
    await apiInstance.get("/api/externalauth/google");
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const facebookLogin = async () => {
  try {
    await apiInstance.get("/api/externalauth/facebook");
  } catch (error) {
    console.error(error);
    throw error;
  }
};
