import apiInstance from "services";

export const getAnswers = async (page: number, pageSize: number): Promise<AnswerArchived[]> => {
  try {
    const response = await apiInstance.get(`/api/answer/get-answers?page=${page}&pageSize=${pageSize}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAnswersCount = async (): Promise<number> => {
  try {
    const response = await apiInstance.get(`/api/answer/get-answers-count`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const prompt = async (Question: string): Promise<Answer | undefined> => {
  try {
    const response = await apiInstance.post("/api/answer/prompt", {
      Question,
    });
    return response.data;
  } 
  catch (error) {
    console.error(error);
    throw error;
  }
};

export const like = async (id: number, isLiked: boolean): Promise<boolean> => {
  try {
    const response = await apiInstance.post("/api/answer/like", {
      AnswerId: id,
      IsLiked: isLiked
    });
    return response.status === 200 ? isLiked : !isLiked;
  } 
  catch (error) {
    console.error(error);
    return !isLiked;
  }
};

export const limits = async (): Promise<AnswerLimits> => {
  try {
    const response = await apiInstance.post("/api/answer/limits");
    return response.data;
  } 
  catch (error) {
    console.error(error);
    throw error;
  }
};

export interface Answer {
  answerID: number;
  question: string;
  answer: string;
  isLiked: boolean;
  createdAtUtc: string;
  answerLimits: AnswerLimits
}

export interface AnswerArchived {
  answerID: number;
  question: string;
  answer: string;
  isLiked: boolean;
  createdAtUtc: string;
}

export interface AnswerLimits {
  answerLimit: number;
  answerCount: number;
  answerResetPeriodSec: number;
  canAnswer: boolean;
}
