import React from "react";

// To be replace later with the actual logo
const AppLogo = () => {
  return (
    <img src="/zennly-logo-text-256.png" alt="logo" className="text-3xl font-bold leading-[45px] text-center text-tertiary w-48"/>
  );
};

export default AppLogo;
