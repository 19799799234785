import React from "react";

interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const AppTextArea: React.FC<TextAreaProps> = ({ className, ...props }) => {
  const commonStyles =
    "rounded-[26px] text-base font-normal leading-6 border border-solid border-primary-50 bg-primary-light text-tertiary placeholder:text-[#A3AFDC] p-4 w-full min-h-full focus:outline-none focus:ring-none";

  const classNames = `${commonStyles} ${className}`;

  return (
    <textarea
      className={classNames}
      placeholder=""
      rows={11}
      {...props}
    />
  );
};

export default AppTextArea;
