import React from "react";
import WelcomeBg from "assets/images/welcome-bg.svg";

import LeafDuoImg from "assets/images/leaf-duo.svg";
import AppButton from "components/Base/AppButton";
import { useLocation, useNavigate } from "react-router-dom";

const SubscriptionConfirmPage = (props: { event: "success" | "cancel" }) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const isSuccess = props.event === "success";
  const params = new URLSearchParams(search);
  const session_id = params.get("session_id") || "";

  return (
    <div
      style={{ backgroundImage: `url(${WelcomeBg})` }}
      className="flex-1 bg-cover bg-no-repeat flex p-2"
    >
      <div className="flex-1 gap-4 flex flex-col items-center justify-center">
        <img src={LeafDuoImg} alt="leaf-duo" className="" />
        <h1 className="text-3xl font-semibold leading-[45px] text-center text-tertiary">
          Welcome To BHP
        </h1>
        <p className="text-sm font-normal leading-6 text-center text-tertiary-100">
          {isSuccess
            ? "Your subscription has been confirmed."
            : "Your subscription has been cancelled."}
        </p>

        <AppButton
          className="mt-2 w-full max-w-[320px] h-[54px]"
          variant="default"
          onClick={() => navigate("/dashboard")}
        >
          Proceed
        </AppButton>
      </div>
    </div>
  );
};

export default SubscriptionConfirmPage;
