import React from "react";
import HeartCircleImg from "assets/images/heart-circle.svg";
import HeartCircleActiveImg from "assets/images/heart-circle-active.svg";
import ExpandIcon from "assets/images/arrow-down-round-outline.svg";
import CollapseIcon from "assets/images/arrow-up-round-outline.svg";


const AnswerCardSmall = (props: { question: string; answer: string; isLiked: boolean; createdDate: string; handleLike: (isLiked: boolean) => void; }) => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <div
      className={`cursor-pointer max-w-[350px] lg:max-w-[400px] app-card rounded-[20px] px-6 py-5 flex flex-col justify-between w-full transition-all duration-300 shadow-lg ${
        expanded ? "h-[400px]" : "h-[170px]"
      }`}
      onClick={() => setExpanded(!expanded)}
    >
      <div className="flex flex-col h-full">
        <div>
          <h2 className="text-base font-semibold text-center text-gray-800">
            {props.question}
          </h2>
        </div>
        {expanded && (
          <p className="mt-4 text-base text-gray-700 overflow-y-auto">
            {props.answer}
          </p>
        )}
      </div>
      <div className="flex items-center justify-between mt-4">
        <img
          src={props.isLiked ? HeartCircleActiveImg : HeartCircleImg}
          alt="heart-circle"
          className="w-[28px] h-[28px] cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            props.handleLike(!props.isLiked);
          }}
        />
        <p className="text-sm text-center text-gray-500">{props.createdDate}</p>
        {expanded ? (
          <button
            className="w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center"
            onClick={(e) => {
              e.stopPropagation();
              setExpanded(false);
            }}
          >
            <img src={CollapseIcon} alt="Collapse" className="w-4 h-4" />
          </button>
        ) : (
          <button
            className="w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center"
            onClick={(e) => {
              e.stopPropagation();
              setExpanded(true);
            }}
          >
            <img src={ExpandIcon} alt="Expand" className="w-4 h-4" />
          </button>
        )}
      </div>
    </div>
  );
};

export default AnswerCardSmall;
