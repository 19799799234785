import React from "react";
import AppRadioButton from "./AppRadioButton";

interface AppRadioGroupProps {
  options: { label: string; value: string }[];
  name: string;
  value: string;
  onChange: (value: string) => void;
}

const AppRadioGroup: React.FC<AppRadioGroupProps> = ({
  options,
  name,
  value,
  onChange,
}) => {
  return (
    <>
      {options.map((option) => (
        <AppRadioButton
          key={option.value}
          label={option.label}
          name={name}
          value={option.value}
          checked={value === option.value}
          onChange={() => onChange(option.value)}
        />
      ))}
    </>
  );
};

export default AppRadioGroup;
