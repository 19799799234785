import React from "react";
import AppLayout from "components/Layout/AppLayout";
import AboutYouForm from "components/Forms/AboutYouForm";

const AboutYouPage = () => {
  return (
    <AppLayout navigation>
      <div className="app-card flex-1 max-h-[666px] max-w-[350px]">
        <AboutYouForm />
      </div>
    </AppLayout>
  );
};

export default AboutYouPage;
