import React from "react";
import DefaultBgImg from "assets/images/default-bg.svg";
import AppLogo from "components/Base/AppLogo";

import BackArrowImg from "assets/images/back-arrow.svg";
import { useNavigate } from "react-router-dom";

interface AppLayoutProps {
  background?: string;
  children: React.ReactNode;
  title?: string;
  navigation?: boolean;
}

const AppLayout: React.FC<AppLayoutProps> = ({
  children,
  title,
  navigation = false,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className="relative flex-1 min-h-screen w-full px-2 flex flex-col items-center justify-center"
      style={{
        backgroundImage: `url(${DefaultBgImg})`,
        backgroundPosition: "top",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="relative text-center min-h-[19vh] flex flex-col items-center justify-center w-full max-w-[320px] lg:max-w-[500px]">
        {navigation && (
          <img
            src={BackArrowImg}
            alt="Back Arrow"
            className="cursor-pointer absolute left-[-30px] top-[30px] mt-5 ml-5"
            onClick={() => {
              navigate(-1);
            }}
          />
        )}
        {title ? (
          <p className="text-[22px] font-semibold leading-[33px] text-center">
            {title}
          </p>
        ) : (
          <AppLogo />
        )}
      </div>

      <div className="max-w-[350px] lg:max-w-[500px] w-full flex flex-col flex-1">
        {children}
      </div>
    </div>
  );
};

export default AppLayout;
