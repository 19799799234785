import React from "react";
import AppButton from "components/Base/AppButton";
import HeartCircleImg from "assets/images/heart-circle.svg";
import HeartCircleActiveImg from "assets/images/heart-circle-active.svg";
import { Answer } from "services/answers";


const AnswerCardFull = (props: { answer: Answer; onDismiss: () => void; handleLike: () => void; }) => {
  const answer = props.answer;

  return (
    <div className="app-card max-w-[350px] pb-[75px] max-h-[480px] relative">
      <p className="text-2xl max-w-[300px] mx-auto px-1 font-semibold leading-10 text-center text-black overflow-hidden overflow-y-auto">
        {answer?.answer}
      </p>
      <img
        src={answer.isLiked ? HeartCircleActiveImg : HeartCircleImg}
        alt="heart-circle"
        className="w-[50px] h-[50px] absolute bottom-[16px] right-[16px] cursor-pointer"
        onClick={props.handleLike}
      />

      <AppButton
        onClick={() => {
          props.onDismiss();
        }}
        className="w-[120px] border-[3px] border-solid border-white h-[120px] px-0 py-0 rounded-full items-center !absolute bottom-[-60px] left-1/2 transform -translate-x-1/2"
        hideIcon
      >
        Thanks
      </AppButton>
    </div>
  );
};

export default AnswerCardFull;
