import AppLayout from "components/Layout/AppLayout";
import LoginForm from "components/Forms/LoginForm";
import RegisterForm from "components/Forms/RegisterForm";
import ConfirmResetForm from "components/Forms/ConfirmResetForm";
import ForgotPasswordForm from "components/Forms/ForgotPasswordForm";
import { confirm } from "services/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const LoginPage = ({ form }: { form: ("login" | "register" | "reset" | "confirm" | "confirm-reset" | "check-email") }) => {

  const ConfirmPage = () => {
    const navigate = useNavigate();
    const { search } = useLocation();

    const params = new URLSearchParams(search);
    const email = params.get('confirm_email') || ""; 
    const token = params.get('token') || "";

    useEffect(() => {
      if (email && token) {
        confirm(email, token)
          .then(() => {
            navigate("/about-you");
          })
          .catch((err) => {
            console.error('Confirmation error:', err);
          });
      }
      else{
        console.error('No email or token found!');
        navigate("/login");
      }
    }, []);

    
    return (
      <div className="w-full flex flex-col items-center gap-6">
        <p className="text-[22px] font-semibold leading-[33px] text-center">
          Confirming your account..
        </p>
      </div>
    );
  }

  const CheckEmailPage = () => {
    return (
      <div className="w-full flex flex-col items-center gap-6">
        <p className="text-[22px] font-semibold leading-[33px] text-center">
          Check your email to confirm your account
        </p>
      </div>
    );
  }

  const switchForm = (form: ("login" | "register" | "reset" | "confirm" | "confirm-reset" | "check-email")) => {
    switch (form) {
      case "login":
        return <LoginForm />;
      case "register":
        return <RegisterForm />;
      case "confirm":
        return <ConfirmPage />;
      case "reset":
        return <ForgotPasswordForm />;
      case "confirm-reset":
        return <ConfirmResetForm />;
      case "check-email":
        return <CheckEmailPage />;
      default:
        return <LoginForm />;
    }
  }


  return (
    <AppLayout>
      <div className="app-card max-h-[666px] max-w-[350px]">
        {switchForm(form)}
      </div>
    </AppLayout>
  );
};

export default LoginPage;
