
import apiInstance from "services";

export const getPlans = async (): Promise<Plan[]> => {
  try {
    const response = await apiInstance.get("/api/plan/get-plans");
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const subscribe = async (planId: number) => {
  try {
    const response = await apiInstance.post("/api/plan/subscribe", {
      PlanId: planId
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const logSubscriptionSession = async (sessionId: string) => {
  try {
    const response = await apiInstance.post("/api/plan/log-checkout-session", {
      sessionId
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};


export interface Plan {
  id: number;
  name: string;
  priceCents: number;
  answerLimit: number;
  answerResetPeriodSeconds: number;
  currency: string;
  billingInterval: string;
  description: string;
  isPopular: boolean;
  isSubscribed: boolean;
}

