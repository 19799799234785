
import apiInstance from "services";

export const getProfile = async () : Promise<Profile | null> => {
  try {
    const response = await apiInstance.get("/api/profile/get-profile");
    if (response.status === 200) {
      return response.data;
    }
    
    return null;

  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createProfile = async (profile: Profile) => {
  try {
    const response = await apiInstance.post("/api/profile/create-profile", profile);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateProfile = async (profile: Profile) => {
  try {
    const response = await apiInstance.put("/api/profile/update-profile", profile);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export interface Profile {
  name: string;
  dateOfBirth: string;
  gender: string;
}

