import React from "react";
import AppLayout from "components/Layout/AppLayout";
import AppMenu from "components/Base/AppMenu";
import AppTextArea from "components/Base/AppTextArea";
import AppButton from "components/Base/AppButton";
import { useNavigate } from "react-router-dom";
import { prompt, like, Answer } from "services/answers";
import { useState } from "react";
import AnswerCardFull from "components/Answer/AnswerCardFull";



const DashboardPage = () => {
  const navigate = useNavigate();

  const [state, setState] = useState<{ question: string; answer: Answer | undefined; isLoading: boolean; }>(
    {
      question: "",
      answer: undefined,
      isLoading: false,
    });

  const handlePrompt = async () => {
    setState({...state, isLoading: true});
    let answer: Answer | undefined = undefined;
    if(state.question && state.question.length > 0) {
      answer = await prompt(state.question);
    }
  
    if (answer) {
      setState({...state, answer, isLoading: false });
    }
    else {
      setState({...state, isLoading: false });
    }
  };

  const handleLikeAnswer = async () => {
    if (!state.answer) return;
    let isLiked = await like(state.answer.answerID, !state.answer.isLiked);
    setState({ ...state, answer: { ...state.answer, isLiked }});
  };

  const outOfAnswers = state.answer?.answerLimits && (state.answer.answerLimits.answerCount  >= state.answer.answerLimits.answerLimit);

  return (
    <AppLayout>
      <div className="flex flex-col items-center w-full gap-5">
        {state.answer && state.answer.answerLimits.canAnswer? (
          <AnswerCardFull
            answer={state.answer}
            onDismiss={() => setState({...state, answer: undefined })}
            handleLike={handleLikeAnswer}
          />
        ):(
          <>
            <p className="text-2xl font-semibold leading-10 text-center max-w-[320px] lg:max-w-none">
            What does your soul<br/>seek to know?
            </p>
            <div className="max-w-[350px] relative">
              <AppTextArea
                className="pb-20"
                onChange={(e) => {
                  setState({...state, question: e.target.value })
                }}
              />
              <AppButton
                onClick={handlePrompt}
                className="w-[120px] border-[3px] border-solid border-white h-[120px] px-0 py-0 rounded-full items-center !absolute bottom-[-60px] left-1/2 transform -translate-x-1/2"
                hideIcon
                glow={state.isLoading}
                disabled={outOfAnswers}
              >
                {state.isLoading ? "Thinking" : (outOfAnswers ? "Quota Exceeded" : "Ask")}
              </AppButton>
            </div>
          </>
        )}
        <div className="mt-20 h-fit w-full justify-center flex flex-cols items-center">
          <AppMenu />
        </div>
      </div>
    </AppLayout>
  );
};

export default DashboardPage;
