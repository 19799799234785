import { useState, useEffect } from "react";
import AppLayout from "components/Layout/AppLayout";
import AppButton from "components/Base/AppButton";

import { AnswerArchived, getAnswers, like } from "services/answers";
import AnswerCardSmall from "components/Answer/AnswerCardSmall";

const AnswersPage = () => {
  const [answers, setAnswers] = useState<AnswerArchived[]>([]);

  useEffect(() => {
    getAnswers(1, 5).then((res) => setAnswers(res));
  }, []);

  const updateAnswerLike =(answerID: number, isLiked: boolean) =>{
    var index = answers.findIndex(x=> x.answerID === answerID);
    if (index === -1)
      // handle error
      console.log("Answer not found");
    else
    setAnswers(
        [
          ...answers.slice(0,index),
          { ...answers[index], isLiked },  
          ...answers.slice(index+1)
        ]
      );
  }

  const handleLikeAnswer = async (answerID: number, isLiked: boolean) => {
    let newIsLiked = await like(answerID, isLiked);
    updateAnswerLike(answerID, newIsLiked);
  };

  return (
    <AppLayout navigation title="Your History">
      <div className="flex flex-col gap-3">
        {answers.map((ans) => (
          <AnswerCardSmall
            key={ans.answerID}
            question={ans.question}
            answer={ans.answer}
            isLiked={ans.isLiked}
            createdDate={convertDate(ans.createdAtUtc)}
            handleLike={(isLiked) => handleLikeAnswer(ans.answerID, isLiked)}
          />
           ))}
      </div>
    </AppLayout>
  );
};

export default AnswersPage;

const convertDate = (dateStr: string) => {
  const [date] = dateStr.split('T');
  const [year, month, day] = date.split('-');
  return `${month}/${day}/${year}`;
};

